/* .body_cont p {
    font-weight: 700;
    font-size: 14px;
    line-height: 30px;
    color: #DCDCDC;   
    padding: 0 0 10px;
} */

.body_cont p {
  color: #dcdcdc;
}


.body_cont .autocomplete {
  position: relative;
  display: inline-block;
}
.body_cont .autocomplete-items {
  position: absolute;
  border: none;
  border-top: none;
  z-index: 9999; /*top: 100%;*/
  left: 0;
  right: 0;
  background: #fff;
}
.body_cont .autocomplete-items div {
  padding: 9px 15px 9px 15px;
  cursor: pointer;
  background-color: #26264e;
  border-bottom: 1px solid #7d70f6;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
}
.body_cont .autocomplete-items div:last-of-type {
  border-bottom: none;
}
.body_cont .autocomplete-items div strong {
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
}
.body_cont .autocomplete-items div:hover {
  background-color: #dcdcdc;
  color: #000;
}
.body_cont .autocomplete-active {
  background-color: #dcdcdc !important;
  color: #000;
}

/****Alert******/
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.alert.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert.alert-success p{
  color: #155724 !important;
}
/***************/

/* Extra large devices (large laptops and desktops, 1025px and up) */
@media only screen and (min-width: 1025px) {
  .body_cont .cont_sec  .transactions table tr td:nth-child(4),
  .body_cont .cont_sec  .actions table tr td:nth-child(4),
  .body_cont .cont_sec  .traces table tr td:nth-child(5){
    word-break: break-all;
  }
}